<template>
  <b-container class="mt-5 d-flex">
        <profile-sidebar class="toggle-main-profile"></profile-sidebar>
    <div class="flex-grow-1">
      <transition  name="router-anim" :enter-active-class="`animated fadeInUp`" mode="out-in"
                  :leave-active-class="`animated fadeOut`">
        <router-view></router-view>
      </transition>
    </div>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import profileSidebar from '@/modules/userProfile/components/profileSidebar'
export default {
  name: 'userProfile',
  components: { profileSidebar },
  mounted () {
    core.index()
  }
}
</script>
<style>
</style>
