<template>
<div class="px-2 position-relative">
  <iq-card  :class="`${isOpen ? 'width-animation-open':'width-animation-close'} over-hidden`">
    <div class="d-flex flex-nowrap top-profile-info pt-3 pb-1">
      <div class="user-cover"
           :style="{ 'background-image': 'url(' + userImg + ')' ,
           'background-size' :  'cover',
           'background-repeat': 'no-repeat'
      }"
      >
      </div>
      <div class="d-flex flex-column justify-content-start user-info-data flex-nowrap">
        <h4 class="p-0 m-0">{{ userData }}</h4>
        <p class="font-size-14 text-muted"> {{ $t('userProfile.lastEnter')}}<span class="font-size-14 text-date">{{d.getFullYear() + '/' + parseInt(d.getMonth() + 1 )+ '/' + d.getDate()}}</span></p>
        <div class="d-flex align-items-center justify-content-center flex-nowrap mt-2">
          <userNotification />
          <b-button variant="success" class="d-flex align-items-center py-2 px-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.113" height="12.112" viewBox="0 0 12.113 12.112">
              <path id="Path_1430" data-name="Path 1430" d="M8.056,2A6.062,6.062,0,1,1,3.775,3.775,6.035,6.035,0,0,1,8.056,2Zm2.756,4.192a.468.468,0,0,0-.242.1L7.168,8.84,5.592,7.264c-.342-.356-1.015.317-.658.658L6.8,9.785a.481.481,0,0,0,.608.044l3.725-2.794a.492.492,0,0,0-.267-.842h-.047Z" transform="translate(-1.999 -2)" fill="#fff"/>
            </svg>
            <span class="pr-1">{{ $t('userProfile.documented') }}</span>
          </b-button>
          <router-link :to="{name: 'userSettings'}">
            <b-button class="user-profile-btn px-3 py-2">{{$t('endUser.userProfile')}}</b-button>
          </router-link>
        </div>
      </div>
    </div>
    <hr>
    <span @click="isOpen = !isOpen"
          :class= "`${isOpen ? 'profile-toggle-btn-open':'profile-toggle-btn-close'} m-0 d-flex  flex-nowrap justify-content-center align-items-center`">
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                  <g id="Group_2811" data-name="Group 2811" transform="translate(-1190 -430)">
                    <g id="Group_3760" data-name="Group 3760">
                      <circle id="Ellipse_150" data-name="Ellipse 150" cx="20.5" cy="20.5" r="20.5" transform="translate(1190 430)" fill="#d39d45"/>
                      <path id="Path_1073" data-name="Path 1073" d="M24.578,10.607l-8.8-8.8a1.884,1.884,0,0,0-2.666,0l-8.8,8.8a1.885,1.885,0,0,0,2.666,2.666l7.471-7.469,7.471,7.469a1.885,1.885,0,0,0,2.666-2.666Z" transform="translate(1219.077 436.059) rotate(90)" fill="#fff" fill-rule="evenodd"/>
                    </g>
                  </g>
                </svg>
              </span>
    <div>
      <ul class="p-0 py-2 profile-list">
        <router-link tag="li" class="profile-list-options" v-for="(item, i) in listMenuItems" :key="i" :to="{name: item.pathName}">
          <div class="d-flex align-items-center">
                    <span
                        v-if="item.icon"
                        v-html="item.icon"
                        class="px-2 icon-profile"
                    ></span>
            <span class="link list-name-profile font-size-22 " style="white-space: nowrap">{{ $t(item.name) }}</span>
          </div>
        </router-link>
      </ul>
      <div class="d-flex justify-content-center mx-5 b-0 mb-4">
        <b-button v-if="isOpen" class="d-flex align-items-center gap_1 px-5 py-3 w-100 iq-bg-secondary border-0" @click="logout">
          <svg xmlns="http://www.w3.org/2000/svg" width="28.961" height="28.961" viewBox="0 0 30.961 30.961">
            <path id="Path_1428" data-name="Path 1428" d="M39.481,24A15.481,15.481,0,1,0,54.961,39.48,15.481,15.481,0,0,0,39.481,24Zm-1.192,4.764a1.191,1.191,0,1,1,2.382,0v8.931a1.191,1.191,0,1,1-2.382,0Zm1.192,22.625a11.906,11.906,0,0,1-6.619-21.8,1.191,1.191,0,1,1,1.327,1.978,9.526,9.526,0,1,0,10.582,0A1.191,1.191,0,1,1,46.1,29.583a11.906,11.906,0,0,1-6.616,21.805Z" transform="translate(-24 -24)" fill="#d62020"/>
          </svg>
          <span class="btn-logOut font-size-18">{{$t('auth.logout')}}</span>
        </b-button>
        <b-button v-else class="d-flex align-items-center px-5 py-3 iq-bg-secondary border-0" @click="logout">
          <svg xmlns="http://www.w3.org/2000/svg" width="28.961" height="28.961" viewBox="0 0 30.961 30.961">
            <path id="Path_1428" data-name="Path 1428" d="M39.481,24A15.481,15.481,0,1,0,54.961,39.48,15.481,15.481,0,0,0,39.481,24Zm-1.192,4.764a1.191,1.191,0,1,1,2.382,0v8.931a1.191,1.191,0,1,1-2.382,0Zm1.192,22.625a11.906,11.906,0,0,1-6.619-21.8,1.191,1.191,0,1,1,1.327,1.978,9.526,9.526,0,1,0,10.582,0A1.191,1.191,0,1,1,46.1,29.583a11.906,11.906,0,0,1-6.616,21.805Z" transform="translate(-24 -24)" fill="#d62020"/>
          </svg>
        </b-button>
      </div>
    </div>
  </iq-card>
</div>
</template>

<script>
// import userProfile from '@/modules/userProfile/services/userProfile'
import authService from '@/modules/auth/services/auth.js'
import userNotification from './userNotification'
export default {
  name: 'profileSidebar',
  components: { userNotification },
  data () {
    return {
      d: new Date(),
      // userInfo: [],
      userData: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.name : '',
      userImg: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.avatar : '',
      isOpen: true,
      listMenuItems: [
        {
          name: 'userProfile.statistics',
          pathName: 'user-home',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="28.465" height="23.848" viewBox="0 0 33.465 28.848">
            <path id="_57c7d773beae88e06aee72ec2abba078" data-name="57c7d773beae88e06aee72ec2abba078" d="M17.229,27.756H11.807A1.8,1.8,0,0,0,10,29.564V40.54a1.8,1.8,0,0,0,1.807,1.807h5.421a1.8,1.8,0,0,0,1.807-1.807V29.564A1.8,1.8,0,0,0,17.229,27.756ZM29.477,23.54H24.056a1.8,1.8,0,0,0-1.807,1.807V40.54a1.8,1.8,0,0,0,1.807,1.807h5.421a1.8,1.8,0,0,0,1.807-1.807V25.347A1.89,1.89,0,0,0,29.477,23.54ZM41.658,13.5H36.237a1.8,1.8,0,0,0-1.807,1.807V40.54a1.8,1.8,0,0,0,1.807,1.807h5.421a1.8,1.8,0,0,0,1.807-1.807V15.24A1.786,1.786,0,0,0,41.658,13.5Z" transform="translate(-10 -13.5)" />
          </svg>`

        },
        /*        {
          name: 'userProfile.statistics',
          pathName: 'statistics',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="28.465" height="23.848" viewBox="0 0 33.465 28.848">
            <path id="_57c7d773beae88e06aee72ec2abba078" data-name="57c7d773beae88e06aee72ec2abba078" d="M17.229,27.756H11.807A1.8,1.8,0,0,0,10,29.564V40.54a1.8,1.8,0,0,0,1.807,1.807h5.421a1.8,1.8,0,0,0,1.807-1.807V29.564A1.8,1.8,0,0,0,17.229,27.756ZM29.477,23.54H24.056a1.8,1.8,0,0,0-1.807,1.807V40.54a1.8,1.8,0,0,0,1.807,1.807h5.421a1.8,1.8,0,0,0,1.807-1.807V25.347A1.89,1.89,0,0,0,29.477,23.54ZM41.658,13.5H36.237a1.8,1.8,0,0,0-1.807,1.807V40.54a1.8,1.8,0,0,0,1.807,1.807h5.421a1.8,1.8,0,0,0,1.807-1.807V15.24A1.786,1.786,0,0,0,41.658,13.5Z" transform="translate(-10 -13.5)" />
          </svg>`

        }, */
        {
          name: 'userProfile.Permissions',
          pathName: 'user-permissions',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="28.466" height="32.483" viewBox="0 0 33.466 37.483">
            <path id="secure" d="M45.308,5.246a1.225,1.225,0,0,0-.885-1.011L29.358.045a1.224,1.224,0,0,0-.657,0L13.636,4.235a1.225,1.225,0,0,0-.885,1.011c-.087.628-2.081,15.474,3.032,22.859a24.052,24.052,0,0,0,12.959,9.344,1.228,1.228,0,0,0,.575,0,24.051,24.051,0,0,0,12.959-9.344C47.389,20.72,45.4,5.875,45.308,5.246Zm-6.563,8.667L28.469,24.189a1.225,1.225,0,0,1-1.732,0l-6.353-6.353a1.225,1.225,0,0,1,0-1.732l1.262-1.262a1.225,1.225,0,0,1,1.732,0L27.6,19.068l8.148-8.148a1.225,1.225,0,0,1,1.732,0l1.262,1.262A1.225,1.225,0,0,1,38.745,13.913Z" transform="translate(-12.296 0)" fill="#646464"/>
          </svg>`
        },
        {
          name: 'userProfile.insurance',
          pathName: 'insurance-profile',
          icon: ` <svg xmlns="http://www.w3.org/2000/svg" width="32.483" height="32.483" viewBox="0 0 37.483 37.483">
            <path id="_17e264738a73720c3ce88a761514fcfb" data-name="17e264738a73720c3ce88a761514fcfb" d="M16.78,1.781a3.282,3.282,0,0,1,5.079,0l1.627,1.988,2.4-.908a3.282,3.282,0,0,1,4.4,2.539L30.7,7.936l2.535.416a3.282,3.282,0,0,1,2.539,4.4l-.908,2.4,1.988,1.628a3.282,3.282,0,0,1,0,5.079L34.87,23.487l.908,2.4a3.282,3.282,0,0,1-2.539,4.4L30.7,30.7l-.415,2.535a3.282,3.282,0,0,1-4.4,2.539l-2.4-.908-1.627,1.988a3.282,3.282,0,0,1-5.079,0l-1.628-1.988-2.4.908a3.282,3.282,0,0,1-4.4-2.539L7.936,30.7,5.4,30.288a3.282,3.282,0,0,1-2.539-4.4l.908-2.4L1.781,21.859a3.282,3.282,0,0,1,0-5.079l1.988-1.628-.908-2.4A3.282,3.282,0,0,1,5.4,8.352l2.535-.416L8.351,5.4a3.282,3.282,0,0,1,4.4-2.539l2.4.908Zm1.239,24.442L29.505,14.737l-2.321-2.321L16.859,22.742l-5.4-5.4L9.135,19.66,15.7,26.223a1.641,1.641,0,0,0,2.321,0Z" transform="translate(-0.578 -0.579)" fill="#646464" fill-rule="evenodd"/>
          </svg>`

        },
        {
          name: 'userProfile.transportation',
          pathName: 'transportation-profile',
          icon: `<svg id="delivery-truck_1_" data-name="delivery-truck (1)" xmlns="http://www.w3.org/2000/svg" width="30.466" height="20.496" viewBox="0 0 33.466 24.496">
            <g id="Group_145" data-name="Group 145" transform="translate(0 0)">
              <g id="Group_144" data-name="Group 144">
                <path id="Path_133" data-name="Path 133" d="M0,234.777a.611.611,0,0,0,.584.665h.932a4.131,4.131,0,0,1,3.706-2.633,4.131,4.131,0,0,1,3.706,2.633h12.11v-5.377H0Z" transform="translate(0 -215.695)" />
                <path id="Path_134" data-name="Path 134" d="M33.218,277.32a2.991,2.991,0,1,0,2.746,2.981A2.871,2.871,0,0,0,33.218,277.32Z" transform="translate(-27.997 -258.785)" />
                <path id="Path_135" data-name="Path 135" d="M285.771,192.312l-2.616-1.248H275v8.816h1.583a3.925,3.925,0,0,1,7.412,0h1.549a.612.612,0,0,0,.584-.665v-6.262A.7.7,0,0,0,285.771,192.312Z" transform="translate(-252.663 -180.133)" />
                <path id="Path_136" data-name="Path 136" d="M280.466,122.15a1.85,1.85,0,0,0-1.684-1.088H275v4.76h7.222Z" transform="translate(-252.663 -116.301)" />
                <path id="Path_137" data-name="Path 137" d="M19.172,67.064H1.856A1.962,1.962,0,0,0,0,69.131V80.023H21.038V69.131s0-.006,0-.01A1.969,1.969,0,0,0,19.172,67.064Z" transform="translate(0 -67.064)" />
                <path id="Path_138" data-name="Path 138" d="M309.051,277.32A2.991,2.991,0,1,0,311.8,280.3h0A2.871,2.871,0,0,0,309.051,277.32Z" transform="translate(-281.425 -258.785)" />
              </g>
            </g>
          </svg>`

        },
        {
          name: 'userProfile.veterinary',
          pathName: 'user-veterinary',
          icon:
              `<svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
            <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
          </svg>`
        },
        {
          name: 'userProfile.events',
          pathName: 'user-events',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26.802" height="24.356" viewBox="0 0 31.802 29.356">
            <path id="event" d="M6,15.785V13.951a5.511,5.511,0,0,1,5.5-5.5h1.835v1.835a2.446,2.446,0,1,0,4.893,0V8.446H25.57v1.835a2.446,2.446,0,1,0,4.893,0V8.446H32.3a5.511,5.511,0,0,1,5.5,5.5v1.835Zm8.562-5.5a1.223,1.223,0,1,0,2.446,0V7.223a1.223,1.223,0,1,0-2.446,0ZM26.793,7.223v3.058a1.223,1.223,0,1,0,2.446,0V7.223a1.223,1.223,0,1,0-2.446,0ZM37.8,17.008V29.852a5.511,5.511,0,0,1-5.5,5.5H11.5a5.511,5.511,0,0,1-5.5-5.5V17.008ZM26.37,23.925H23.844l-.782-2.4a1.223,1.223,0,0,0-2.324,0l-.78,2.4H17.432a1.223,1.223,0,0,0-.719,2.213l2.043,1.484-.78,2.4a1.223,1.223,0,0,0,1.882,1.367L21.9,29.907l2.043,1.484a1.223,1.223,0,0,0,1.882-1.367l-.78-2.4,2.043-1.484a1.223,1.223,0,0,0-.719-2.213Z" transform="translate(-6 -6)" />
          </svg>`

        },
        {
          name: 'userProfile.marketplace',
          pathName: 'user-marketplace',
          icon: `<svg id="_58ec6627826e87c5e999998c7fdd9d50" data-name="58ec6627826e87c5e999998c7fdd9d50" xmlns="http://www.w3.org/2000/svg" width="29.817" height="30.79" viewBox="0 0 34.817 35.79">
                <ellipse id="Ellipse_147" data-name="Ellipse 147" cx="3.441" cy="3.441" rx="3.441" ry="3.441" transform="translate(24.652 28.909)" fill="#646464"/>
                <ellipse id="Ellipse_148" data-name="Ellipse 148" cx="3.441" cy="3.441" rx="3.441" ry="3.441" transform="translate(9.645 28.909)" fill="#646464"/>
                <path id="Path_1424" data-name="Path 1424" d="M38.281,29.152a1.476,1.476,0,0,1-1.558,1.376H14.255a4.6,4.6,0,0,1-4.55-3.18l-.576-2.325L4.7,7.351l-.67-2.67A1.389,1.389,0,0,1,5.218,3.029a1.579,1.579,0,0,1,1.87,1.046l.763,3.055,4.472,17.892.421,1.707a1.543,1.543,0,0,0,1.511,1.046H36.723A1.476,1.476,0,0,1,38.281,29.152Z" transform="translate(-3.995 -2.995)" fill="#646464"/>
                <path id="Path_1425" data-name="Path 1425" d="M38.476,11.216,35.5,20.85a4.652,4.652,0,0,1-4.52,3.042H8.877L4.45,6.22A1.782,1.782,0,0,1,5.307,6H33.956a4.917,4.917,0,0,1,3.723,1.62A3.752,3.752,0,0,1,38.476,11.216Z" transform="translate(-3.824 -1.865)" fill="#646464"/>
              </svg>`
        },
        {
          name: 'userProfile.wallet',
          pathName: 'indexWallet',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26.483" height="25.966" viewBox="0 0 31.483 30.966">
            <path id="c275da62a737e9e440232d6c41c485f7" d="M29.548,9.616H4.952V8.632L26.6,6.9V8.632h2.952V5.681A3.324,3.324,0,0,0,25.654,2.3L5.9,5.124A4.688,4.688,0,0,0,2,9.616V29.293a3.935,3.935,0,0,0,3.935,3.935H29.548a3.935,3.935,0,0,0,3.935-3.935V13.552A3.935,3.935,0,0,0,29.548,9.616ZM26.6,23.4a2.953,2.953,0,1,1,2.954-2.952A2.953,2.953,0,0,1,26.6,23.4Z" transform="translate(-2 -2.263)" />
          </svg>`
        },
        {
          name: 'userProfile.favourite',
          pathName: 'favourite',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33.151" height="26.662" viewBox="0 0 38.151 31.662">
              <path id="c74aa330893241edd41bc0bfbdb19920" d="M2.2,14.62a13.44,13.44,0,0,0,3.933,10.03C9.28,27.8,19.7,34.875,20.1,35.269a2.8,2.8,0,0,0,1.18.393,2.8,2.8,0,0,0,1.18-.393c.393-.393,10.816-7.276,13.963-10.62a13.44,13.44,0,0,0,3.933-10.03A10.575,10.575,0,0,0,29.732,4a10.468,10.468,0,0,0-8.26,4.523A10.592,10.592,0,0,0,2.2,14.62Z" transform="translate(-2.2 -4)" fill="#646464"/>
            </svg>`
        },
        {
          name: 'userProfile.comments',
          pathName: 'user-comments',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="29.929" height="27.245" viewBox="0 0 34.929 32.245">
            <path id="b943b33482a2e9d71364e54937c0250a" d="M7.237,2A6.237,6.237,0,0,0,1,8.237V20.712A6.237,6.237,0,0,0,7.237,26.95H8.485V31.8a2.445,2.445,0,0,0,4.055,1.84l7.641-6.686h9.511a6.237,6.237,0,0,0,6.237-6.237V8.237A6.237,6.237,0,0,0,29.692,2Z" transform="translate(-1 -2)" fill="#646464"/>
          </svg>`
        }
        // {
        //   name: 'userProfile.settings',
        //   pathName: 'user-settings',
        //   icon:
        //       `<svg xmlns="http://www.w3.org/2000/svg" width="30.135" height="27.135" viewBox="0 0 30.135 30.135">
        //     <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
        //   </svg>`
        // }
      ]
    }
  },
  methods: {
    logout () {
      authService.logout()
      localStorage.clear()
      this.$router.push({ name: 'index' })
      window.location.reload()
    }
    // getUserData () {
    //   userProfile.getUserData().then(res => {
    //     this.userInfo = res.data
    //   })
    // }
  },
  created () {
    // this.getUserData()
  }
}
</script>

<style>
.w-90{
  width: 90% !important;
}
.top-profile-info{
  width: 400px;
}
.font-size-27{
  font-size: 27px !important;
}
.text-check{
  border-radius: 10px;
  padding: 3px;
  background: #ffff;
  color: red;
}
.profile-btn-active-icon{
  width: 12px !important;
  height: 12px !important;
}
/*.profile-toggle-btn-open{*/
/*top: 130px;*/
/*left: -13px;*/
/*  transition: all 0.7s ease-in-out;*/
/*  position: absolute;*/
/*}*/
/*.profile-toggle-btn-close{*/
/*  top: 130px;*/
/*  left: -13px;*/
/*  transition: all 0.7s ease-in-out;*/
/*  position: absolute;*/
/*  transform: rotate(180deg);*/
/*}*/
/*.user-cover{*/
/*  width: 76px;*/
/*  height: 76px;*/
/*  border: 0.5px solid #D39D45;*/
/*  border-radius: 10px;*/
/*  margin-right: 15px*/
/*}*/
.user-cover img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.profile-list-options svg path {
  fill: var(--iq-secondary-dark);
}
/*.profile-list-options{*/

/*  padding-top: 10px;*/
/*  padding-bottom: 10px;*/
/*  padding-right: 36px;*/
/*}*/
.profile-list-options:hover{
  cursor: pointer;
  background-color: #D39D451D ;
  color: #D39D45 !important;
  transition: all 0.4s ease-in-out;
}
.profile-list-options:hover svg path{
  fill: #D39D45 !important;
}
.profile-list-options:hover svg, .profile-list-options:hover svg path{
  fill: #D39D45 !important;
}
.profile-list .router-link-exact-active, .router-link-exact-active svg {
  cursor: pointer;
  background-color: #D39D451D ;
  color: #D39D45 !important;
  transition: all 0.4s ease-in-out;
}
.profile-list .router-link-active , .profile-list .router-link-active svg path{
  cursor: pointer;
  background-color: #D39D451D ;
  color: #D39D45 !important;
  transition: all 0.4s ease-in-out;
  fill: #D39D45 !important;
  font-weight: bold;
}
.width-animation-open {
  transition: all 0.7s ease-in-out;
  width: 385px;
}
.width-animation-close {
  transition: all 0.7s ease-in-out;
  width: 109px;
}
.over-hidden {
  overflow: hidden;
}
/*.btn-logOut{*/
/*  color: #D62020;*/
/*  padding-right: 20px;*/
/*}*/
</style>
